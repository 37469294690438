import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { DeviceOrientation } from '@ionic-native/device-orientation/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared/shared.module';

import * as Hammer from 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Environment } from '../environments/environment';
import { UserAccoountServiceProvider } from './providers/servicer/user-account-service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'tap': { direction: Hammer.DIRECTION_ALL },
  };
}


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
          scrollAssist: false,
          scrollPadding: false
        }),
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        HammerModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: Environment.serviceWorkerRegister,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
        StatusBar,
        SplashScreen,
        Geolocation,
        BackgroundGeolocation,
        BackgroundMode,
        ScreenOrientation,
        BLE,
        LocalNotifications,
        AndroidPermissions,
        FCM,
        AppVersion,
        DeviceOrientation,
        UserAccoountServiceProvider
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }
